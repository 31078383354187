
import axios from "axios";


console.log("REACT_APP_ENVIROMENT", process.env.REACT_APP_ENVIROMENT)
var API_ENDPOINT;
var IMAGE_ENDPOINT;
if (process.env.REACT_APP_ENVIROMENT === "dev") {
  API_ENDPOINT = 'https://devapp.tathmeer.io';
  IMAGE_ENDPOINT = 'https://tathmeer.s3.us-east-2.amazonaws.com/';
} else if (process.env.REACT_APP_ENVIROMENT === "staging") {
  API_ENDPOINT = 'https://stagingapp.tathmeer.io';
  IMAGE_ENDPOINT = 'https://tathmeer.s3.us-east-2.amazonaws.com/';
} else if (process.env.REACT_APP_ENVIROMENT === "production") {
  API_ENDPOINT = 'https://app.tathmeer.io';
  IMAGE_ENDPOINT = 'https://tathmeer-s3.s3.me-central-1.amazonaws.com/';
} else {
  API_ENDPOINT = 'http://localhost:5000';
  IMAGE_ENDPOINT = 'https://tathmeer.s3.us-east-2.amazonaws.com/';

}
export const IMAGE_URL = IMAGE_ENDPOINT
export const API_URL = API_ENDPOINT;


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')


export const ROLE_OPTIONS = [
  { label: "Technology Provider", value: "technology" },
  { label: "Buy Vendor", value: "buyvendor" },
  { label: "Sell Vendor", value: "sellvendor" },
  { label: "Invest Company", value: "investcompany" },

]


export const STATUS_OPTION = [{ label: "Active", value: "Active" }, { label: "De-Active", value: "De-Active" }]

export const STATUS_OPTION_FOR_ADMIN = [{ label: "Pending For Approval", value: "Pending For Approval" }]



export const KYC_DOCUMENT_MANDATPRY_ROLES = ["technology", "buyvendor", "sellvendor", "investcompany"]



export const uploadFileServer = async (data) => {
  try {
    var response = await axios.post(`/upload/s3`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}

export const getFileExtension = (filename) => {
  // Find the index of the last dot in the filename
  const lastDotIndex = filename.lastIndexOf('.');

  // If there's no dot or it's the first character, return an empty string (no extension)
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return "";
  }

  // Extract the extension by slicing the filename from the position after the last dot
  const extension = filename.slice(lastDotIndex + 1);

  return extension;
}


export const handleAxiosError = (error) => {
  let errorMessage = 'An error occurred';
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorMessage = error.response.data.message || errorMessage;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = 'Request Error: Please check your internet connection';
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message || errorMessage;
  }
  return errorMessage;
};

export const addMilesSeparator = (n) => {
  if (n == 0) {
    return 0
  }
  n = Math.round(n * 100000) / 100000;
  const parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const end = numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  return end;
}